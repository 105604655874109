import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import RoomIcon from '@material-ui/icons/Room'
import ListItem from '@material-ui/core/ListItem'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import WorkOffIcon from '@material-ui/icons/WorkOff'
import AssignmentIcon from '@material-ui/icons/Assignment'
import HistoryIcon from '@material-ui/icons/History'
import DialpadIcon from '@material-ui/icons/Dialpad'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PropTypes from 'prop-types'
import UserContext, { SelectedMenuItemContext } from '../../../Context/Context'
import './Menu.css'

// https://codesandbox.io/s/material-demo-forked-jpsiv?file=/demo.js:2875-2985
// https://material-ui.com/components/drawers/#persistent-drawer

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  selected: {
    '&.Mui-selected': {
      backgroundColor: '#9e9e9e',
      color: 'white',
      fontWeight: 600,
    },
  },
}))

const Menu = ({ MenuOpen, MenuCloseIconClick }) => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const [userDetails] = React.useContext(UserContext)
  const [menuPermission, setMenuPermission] = React.useState({
    ViewProperty: false,
    ViewTasks: false,
  })
  const open = MenuOpen
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [popoverText, setPopoverText] = React.useState(null)
  const [selectedMenu, setSelectedMenu] = React.useContext(
    SelectedMenuItemContext
  )
  const handlePopoverOpen = (event) => {
    setPopoverText(event.currentTarget.offsetParent.innerText)
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const popoverOpen = Boolean(anchorEl)
  //   const handleDrawerOpen = () => {
  //     setOpen(true)
  //   }

  const onListItemClick = (event) => {
    setSelectedMenu(parseInt(event?.currentTarget?.id))
    history.push(event.currentTarget.getAttribute('path'))
  }

  useEffect(() => {
    const obj = {
      ViewProperty: false,
      ViewTasks: false,
    }

    userDetails[0]?.user_permissions?.forEach((element) => {
      if (element.name === 'Can view Property') obj.ViewProperty = true
      if (element.name === 'Can view Property Task') obj.ViewTasks = true
    })
    setMenuPermission(obj)
  }, [userDetails])

  return (
    <div>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={`${clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}`}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={`${classes.toolbar} menuParent`}>
          {/* <IconButton onClick={MenuCloseIconClick}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton> */}
        </div>
        <Divider />
        <List>
          {menuPermission.ViewProperty && (
            <MenuItem
              id="1"
              button
              onClick={onListItemClick}
              path="/property"
              key="Properties"
              selected={selectedMenu === 1}
              className={classes.selected}
            >
              <ListItemIcon
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <HomeWorkIcon />
              </ListItemIcon>
              <ListItemText primary="Properties" />
            </MenuItem>
          )}
          {menuPermission.ViewProperty && (
            <MenuItem
              id="6"
              button
              onClick={onListItemClick}
              path="/property-sold"
              key="PropertiesSold"
              selected={selectedMenu === 6}
              className={classes.selected}
            >
              <ListItemIcon
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <WorkOffIcon />
              </ListItemIcon>
              <ListItemText primary="Sold" />
            </MenuItem>
          )}
          {menuPermission.ViewTasks && (
            <MenuItem
              id="2"
              button
              onClick={onListItemClick}
              path="/tasks"
              key="Tasks"
              selected={selectedMenu === 2}
              className={classes.selected}
            >
              <ListItemIcon
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="Tasks" />
            </MenuItem>
          )}
          <MenuItem
            id="3"
            button
            onClick={onListItemClick}
            path="/propertyhistory"
            key="History"
            selected={selectedMenu === 3}
            className={classes.selected}
          >
            <ListItemIcon
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText primary="History" />
          </MenuItem>
          <MenuItem
            id="4"
            button
            onClick={onListItemClick}
            path="/map"
            key="Map"
            selected={selectedMenu === 4}
            className={classes.selected}
          >
            <ListItemIcon
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <RoomIcon />
            </ListItemIcon>
            <ListItemText primary="Map" />
          </MenuItem>
          <MenuItem
            id="5"
            button
            onClick={onListItemClick}
            path="/"
            key="Reports"
            selected={selectedMenu === 5}
            className={classes.selected}
          >
            <ListItemIcon
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </MenuItem>
          {/* <MenuItem
            id="6"
            button
            onClick={onListItemClick}
            key="Calculator"
            selected={selectedMenu === 6}
            className={classes.selected}
          >
            <ListItemIcon
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <DialpadIcon />
            </ListItemIcon>
            <ListItemText primary="Calculator" />
          </MenuItem> */}
        </List>
        <Divider />
        {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={popoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{popoverText}</Typography>
      </Popover>
    </div>
  )
}

Menu.propTypes = {
  MenuCloseIconClick: PropTypes.func,
  MenuOpen: PropTypes.bool,
}

Menu.defaultProps = {
  MenuCloseIconClick: () => null,
  MenuOpen: false,
}

export default Menu
