import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { BrowserRouter as Router } from 'react-router-dom'
import ErrorBoundary from '../views/pages/ErrorBoundary/ErrorBoundary'
import Routes from '../views/Routes/Routes'
import './App.css'
import Header from '../views/components/custom/Header/Header'
import Menu from '../views/components/custom/Menu/Menu'
import UserContext, {
  MetaDataContext,
  SelectedPropertyContext,
  GlobalMethodContext,
  SelectedMenuItemContext,
  ApplicationStatusContext,
  DepartmentContext,
} from '../views/Context/Context'
import getAPIData from '../models/api/api'
import APIEndPoints from '../models/api/apiEndpoints'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}))
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
const App = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [snackbarOpen, setsnackbarOpen] = React.useState(false)
  const [snackbarMsg, setsnackbarMsg] = React.useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('info')
  const [userDetails, setUserDetails] = React.useState('')
  const [appStatusList, setAppStatusList] = React.useState([])
  const [departmentsList, setDepartmentsList] = React.useState([])
  const [metaData, setMetaData] = React.useState()
  const [selectedProperty, setSelectedProperty] = React.useState()
  const [selectedMenu, setSelectedMenu] = React.useState(5)
  useEffect(() => {
    if (localStorage.userToken) {
      getAPIData(
        APIEndPoints.GetProfiles.method,
        `${APIEndPoints.GetProfiles.url}?username=${localStorage.UsernameLandBank}`
        //`${APIEndPoints.GetProfiles.url}?username=root`
      ).then((value) => {
        setUserDetails(value.data)
      })
    }
    getAPIData(
      APIEndPoints.GetApplicationStatus.method,
      `${APIEndPoints.GetApplicationStatus.url}`
    ).then((value) => {
      setAppStatusList(value.data)
    })
    getAPIData(
      APIEndPoints.GetDepartments.method,
      `${APIEndPoints.GetDepartments.url}`
    ).then((value) => {
      setDepartmentsList(value.data)
    })
    const cities = getAPIData(
      APIEndPoints.GetCities.method,
      APIEndPoints.GetCities.url
    )
    const countries = getAPIData(
      APIEndPoints.GetCountries.method,
      APIEndPoints.GetCountries.url
    )
    const districts = getAPIData(
      APIEndPoints.GetDistricts.method,
      APIEndPoints.GetDistricts.url
    )
    const states = getAPIData(
      APIEndPoints.GetStates.method,
      APIEndPoints.GetStates.url
    )
    Promise.all([cities, countries, districts, states]).then((values) => {
      const metaValus = new Map()

      values.map((val) =>
        // TODO: We are using API URL as Key of meta data, if URL change we need to change the key in the place of reading below metaValus.
        metaValus.set(val.config.url.slice(7, -1), val.data)
      )
      setMetaData(metaValus)
    })
  }, [])

  const menuIconClick = () => {
    setOpen(!open)
  }
  const menuCloseIconClick = () => {
    setOpen(false)
  }
  const snackbarCloseHandler = () => {
    setsnackbarOpen(false)
  }
  const globalMethods = {
    showSnackbar: (openSnackbar, severity, message) => {
      setSnackbarSeverity(severity)
      setsnackbarOpen(openSnackbar)
      setsnackbarMsg(message)
    },
  }
  return (
    <Router>
      <GlobalMethodContext.Provider value={globalMethods}>
        <MetaDataContext.Provider value={[metaData, setMetaData]}>
          <UserContext.Provider value={[userDetails, setUserDetails]}>
            <SelectedPropertyContext.Provider
              value={[selectedProperty, setSelectedProperty]}
            >
              <SelectedMenuItemContext.Provider
                value={[selectedMenu, setSelectedMenu]}
              >
                <ApplicationStatusContext.Provider
                  value={[appStatusList, setAppStatusList]}
                >
                  <DepartmentContext.Provider
                    value={[departmentsList, setDepartmentsList]}
                  >
                    <div className="app">
                      <ErrorBoundary>
                        {localStorage.userToken && (
                          <>
                            <Header MenuIconClick={menuIconClick} />
                            <Menu
                              MenuOpen={open}
                              MenuCloseIconClick={menuCloseIconClick}
                            />
                          </>
                        )}
                        <Snackbar
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          open={snackbarOpen}
                          onClose={snackbarCloseHandler}
                          key="topcenter"
                        >
                          <Alert severity={snackbarSeverity}>
                            {snackbarMsg}
                          </Alert>
                        </Snackbar>
                        <main className={`${classes.content} routes-container`}>
                          <div
                            className={`${classes.toolbar} support-header`}
                          />
                          <div className="pageContainer">
                            <Routes />
                          </div>
                        </main>
                      </ErrorBoundary>
                    </div>
                  </DepartmentContext.Provider>
                </ApplicationStatusContext.Provider>
              </SelectedMenuItemContext.Provider>
            </SelectedPropertyContext.Provider>
          </UserContext.Provider>
        </MetaDataContext.Provider>
      </GlobalMethodContext.Provider>
    </Router>
  )
}

export default App
